import React from 'react'

export default function ElegantHero({groom, bride , photo , preview , date , guest , extraInf}) {

  return (
    
        <div className='h-screen' >
        
                <div className="absolute inset-0">
                  <img
                    className={`${preview ? "  h-1/6 ": "h-full"} w-full object-cover`}
                    src={photo}
                    alt="wedding"
                  />
                </div>
                {
                  (guest.id|| extraInf.logo) &&
                  
                  <div className="absolute text-black top-0 w-full bg-white py-6 opacity-80 text-center">
            
                   <span className="relative">
                    {
                      extraInf.logo && 
                      <img
                      className="mx-auto w-28"
                      src={extraInf.logo}
                      alt="wedding"
                      />
                    }
                       <h2 className=" animate-typing text-xl uppercase  font-serif  text-black sm:text-2xl">{guest.nameInvitation}</h2>
                   </span>
                   {/* <p className='font-serif'>Te invitamos a : </p> */}

            
          </div>
                }


                <div className="absolute  bottom-0 w-full bg-white py-6 opacity-80">
                
                    <p className="font-serif tracking-widest mx-auto  my-2 text-center text-md sm:text-lg text-black ">
                    NUESTRA BODA
                    </p>
                    <h1 className="text-center text-3xl sm:text-5xl">
                    <span className="uppercase block font-serif text-black">{groom} & {bride}</span>
                    </h1>
                    <p className="font-serif tracking-widest mx-auto  my-2 text-center text-md sm:text-lg text-black ">
                    {new Date(date + "T00:00:00").toLocaleString("es-MX" , { dateStyle: 'long', timeZone: 'America/Mexico_City' })}
                    </p>
                </div>  
        </div>
   
  )
}
